import React from "react";
import ReactDOM from "react-dom";
import {PersistGate} from "redux-persist/integration/react";
import {Provider} from "react-redux";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import configureStore from "./store/configureStore";
export const {store, persistor} = configureStore();

function importBuildTarget() {
  if (process.env.REACT_APP_BUILD_TARGET === "Fixture") {
    return import("./FixtureWidgetApp");
  } else if (process.env.REACT_APP_BUILD_TARGET === "Ladder") {
    return import("./LadderWidgetApp");
  } else {
    return Promise.reject(
      new Error("No such build target: " + process.env.REACT_APP_BUILD_TARGET)
    );
  }
}

importBuildTarget()?.then(({ default: WidgetApp }) => {
  const rootElement = document.getElementById(`playhq-${process.env.REACT_APP_BUILD_TARGET?.toLowerCase()}-widget-app-root`);
  if (!rootElement) {
    return console.log(`FATAL ERROR: App root not found in DOM`)
  }

  return ReactDOM.render(
      <React.StrictMode>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <WidgetApp widgetConfig={rootElement.dataset}/>
          </PersistGate>
        </Provider>
      </React.StrictMode>,
      rootElement
    )
  }
);




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
