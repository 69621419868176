import {
  SET_SEASONS_FOR_ORGANISATION_DATA,
  SET_TEAMS_FOR_SEASONS,
  SET_FIXTURES_FOR_TEAM,
  SET_GRADES_FOR_SEASON,
  SET_LADDER_FOR_GRADE,
  SET_FIXTURE_FOR_GRADE,
  SET_DISTINCT_FIXTURE_POOL,
  SET_CURRENT_ROUND_FIXTURE,
  SET_LADDER_COMPETITION_FORMAT,
  SET_BYES_IN_ROUNDS
} from "../Actions/actionTypes";
import {ReduxState, SeasonsForOrganisation} from "../../types/AppTypeDefinition/AppTypeDefinition";

const initialState = {
  associations: null,
  competitionsInAssociation: null,
  seasonsInCompetition: null,
  teamsInSeasons: null,
  fixturesForTeams: null,
  distinctGradeFixture: null,
  gradesInSeason: null,
  distinctFixturePool: null,
  ladderForGrade: null,
  currentFixtureRound: [],
  fixtureForGrade: null,
  distinctRoundsInFixture: null,
  fixtureMappedToTeamObject: null,
  ladderTypes: [],
  byesInRounds: null
};
// Add types to ActionType if needed
type ActionType = ReduxState | ReduxState<SeasonsForOrganisation> | any;

const mapperReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case SET_SEASONS_FOR_ORGANISATION_DATA:
      if(action.payload) {
        return {
          ...state,
          associations: action.payload.associations,
          competitionsInAssociation:  action.payload.competitionsInAssociation,
          seasonsInCompetition: action.payload.seasonsInCompetition
        };
      }else{
        return state;
      }
    case SET_TEAMS_FOR_SEASONS:
      return {
        ...state,
        teamsInSeasons: action.payload
      }
    case SET_FIXTURES_FOR_TEAM:
      if(action.payload) {
        return {
          ...state,
          fixturesForTeams: action.payload[0],
          distinctGradeFixture: action.payload[1],
          distinctFixturePool: action.payload[2],
          currentFixtureRound: action.payload[3]
        }
      }
      return {
        ...state,
        fixturesForTeams: null
      }
    case SET_GRADES_FOR_SEASON:
      return {
        ...state,
        gradesInSeason: action.payload
      }
    case SET_LADDER_FOR_GRADE:
      return {
        ...state,
        ladderForGrade: action.payload
      }
    case SET_FIXTURE_FOR_GRADE:
      if (action.payload) {
        return {
          ...state,
          fixtureForGrade: action.payload[0],
          distinctRoundsInFixture: action.payload[1],
          byesInRounds: action.payload[2]
        }
      }
      return {
        ...state,
        fixtureForGrade: null,
        distinctRoundsInFixture: null
      }
    case SET_DISTINCT_FIXTURE_POOL:
      return {
        ...state,
        distinctFixturePool: action.payload,
      }
    case SET_CURRENT_ROUND_FIXTURE:
      return {
        ...state,
        currentFixtureRound: action.payload,
      }
    case SET_LADDER_COMPETITION_FORMAT:
      return {
        ...state,
        competitionFormats: action.payload
      }
    case SET_BYES_IN_ROUNDS:
      return {
        ...state,
        byesInRounds: action.payload
      }
    default:
      return state;
  }
};

export default mapperReducer;
