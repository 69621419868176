import {START_FIXTURE_LOADING, START_LOADING, STOP_FIXTURE_LOADING, STOP_LOADING} from "../Actions/actionTypes";
import {ReduxState} from "../../types/AppTypeDefinition/AppTypeDefinition";

const initialState = {
  loading: false,
  fixtureLoading: false
};

type ActionTypes = ReduxState;

const uiReducer = (state = initialState, action: ActionTypes) => {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        loading: true
      }
    case STOP_LOADING:
      return {
        ...state,
        loading: false
      }
    case START_FIXTURE_LOADING:
      return {
        ...state,
        fixtureLoading: true
      }
    case STOP_FIXTURE_LOADING:
      return {
        ...state,
        fixtureLoading: false
      }
    default:
      return state
  }
}

export default uiReducer;











