//API MAPPER TYPES
export const SET_SEASONS_FOR_ORGANISATION_DATA = "SET_SEASONS_FOR_ORGANISATION_DATA";
export const SET_TEAMS_FOR_SEASONS = "SET_TEAMS_FOR_SEASONS";
export const SET_FIXTURES_FOR_TEAM = "SET_FIXTURES_FOR_TEAM";
export const SET_GRADES_FOR_SEASON = "SET_GRADES_FOR_SEASON";
export const SET_LADDER_FOR_GRADE = "SET_LADDER_FOR_GRADE";
export const SET_FIXTURE_FOR_GRADE = "SET_FIXTURE_FOR_GRADE";
export const SET_DISTINCT_FIXTURE_POOL = "SET_DISTINCT_FIXTURE_POOL";
export const SET_CURRENT_ROUND_FIXTURE = "SET_CURRENT_ROUND_FIXTURE";
export const SET_LADDER_COMPETITION_FORMAT = "SET_LADDER_COMPETITION_FORMAT";
export const SET_BYES_IN_ROUNDS = "SET_BYES_IN_ROUNDS";

//FIXTURE FILTER TYPES
export const SET_SELECTED_ASSOCIATION_FIXTURE = "SET_SELECTED_ASSOCIATION_FIXTURE";
export const SET_SELECTED_COMPETITION_FIXTURE = "SET_SELECTED_COMPETITION_FIXTURE";
export const SET_SELECTED_SEASONS_FIXTURE = "SET_SELECTED_SEASONS_FIXTURE";
export const SET_SELECTED_TEAM_DATA_FIXTURE = "SET_SELECTED_TEAM_DATA_FIXTURE";
export const SET_SELECTED_GRADE_FIXTURE = "SET_SELECTED_GRADE_FIXTURE";
export const SET_SELECTED_ROUND_FIXTURE = "SET_SELECTED_ROUND_FIXTURE";
export const SET_SELECTED_POOL_FIXTURE = "SET_SELECTED_POOL_FIXTURE";

//LADDER FILTER TYPES
export const SET_SELECTED_ASSOCIATION_LADDER = "SET_SELECTED_ASSOCIATION_LADDER";
export const SET_SELECTED_COMPETITION_LADDER = "SET_SELECTED_COMPETITION_LADDER";
export const SET_SELECTED_SEASONS_LADDER = "SET_SELECTED_SEASONS_LADDER";
export const SET_SELECTED_TEAM_DATA_LADDER = "SET_SELECTED_TEAM_DATA_LADDER";
export const SET_SELECTED_GRADE_LADDER = "SET_SELECTED_GRADE_LADDER";
export const SET_SELECTED_ROUND_LADDER = "SET_SELECTED_ROUND_LADDER";
export const SET_SELECTED_POOL_LADDER = "SET_SELECTED_POOL_LADDER";
export const SET_SELECTED_COMPETITION_FORMAT_LADDER = "SET_SELECTED_COMPETITION_FORMAT_LADDER";

//UI TYPES
export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const START_FIXTURE_LOADING = "START_FIXTURE_LOADING";
export const STOP_FIXTURE_LOADING = "STOP_FIXTURE_LOADING";
