import {
  SET_SELECTED_ASSOCIATION_LADDER,
  SET_SELECTED_COMPETITION_LADDER,
  SET_SELECTED_GRADE_LADDER,
  SET_SELECTED_POOL_LADDER,
  SET_SELECTED_ROUND_LADDER,
  SET_SELECTED_SEASONS_LADDER,
  SET_SELECTED_TEAM_DATA_LADDER,
  SET_SELECTED_COMPETITION_FORMAT_LADDER
} from "../Actions/actionTypes";

const initialState = {
  selectedAssociationObject: null,
  selectedCompetitionsInAssociationObject: null,
  selectedSeasonsInCompetition: null,
  selectedTeamObject: null,
  selectedGradeObject: null,
  selectedRoundObject: null,
  selectedPoolObject: null,
  selectedCompetitionFormat: null
};

const selectedLadderFiltersReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_SELECTED_ASSOCIATION_LADDER:
      return {
          ...state,
          selectedAssociationObject: action.payload,
        };
    case SET_SELECTED_COMPETITION_LADDER:
      if (action.payload) {
        return {
          ...state,
          selectedCompetitionsInAssociationObject: action.payload,
        };
      }

      return {
        ...state,
        selectedCompetitionsInAssociationObject: null,
      }
    case SET_SELECTED_SEASONS_LADDER:
      if (action.payload) {
        return {
          ...state,
          selectedSeasonsInCompetition: {...action.payload},
        };
      }
      return {
        ...state,
        selectedSeasonsInCompetition: [],
      }
    case SET_SELECTED_TEAM_DATA_LADDER:
      if(action.payload) {
        return {
          ...state,
          selectedTeamObject: action.payload,
        };
      }
      return {
        ...state,
        selectedTeamObject: null,
      }

    case SET_SELECTED_GRADE_LADDER:
      return {
        ...state,
        selectedGradeObject: action.payload,
      };
    case SET_SELECTED_ROUND_LADDER:
      if(action.payload) {
        return  {
          ...state,
          selectedRoundObject: action.payload
        }
      }
      return  {
        ...state,
        selectedRoundObject: null
      }
    case SET_SELECTED_POOL_LADDER:
      if(action.payload) {
        return  {
          ...state,
          selectedPoolObject: action.payload
        }
      }
      return  {
        ...state,
        selectedPoolObject: null
      }
    case SET_SELECTED_COMPETITION_FORMAT_LADDER:
      return {
        ...state,
        selectedCompetitionFormat: action.payload
      }
    default:
      return state;
  }
};

export default selectedLadderFiltersReducer;
