import { createStore, combineReducers, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import mapperReducer from "./Reducer/mapperReducer";
import { composeWithDevTools } from 'redux-devtools-extension';
import selectedFixtureFiltersReducer from "./Reducer/selectedFixtureFiltersReducer";
import uiReducer from "./Reducer/uiReducer";
import selectedLadderFiltersReducer from "./Reducer/selectedLadderFiltersReducer";
const widgetType = process.env.REACT_APP_BUILD_TARGET;

const rootReducer = combineReducers({
    mapperReducer: mapperReducer,
    selectedFixtureFiltersReducer: selectedFixtureFiltersReducer,
    selectedLadderFiltersReducer: selectedLadderFiltersReducer,
    ui: uiReducer
});

const persistConfig = {
  key: `root_${widgetType}`,
  storage,
  whitelist: widgetType === "Fixture" ? ['selectedFixtureFiltersReducer'] : ['selectedLadderFiltersReducer']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const configureStore = () => {
  const store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(thunk))
  );

  const persistor = persistStore(store);
  return {store, persistor};
};

export default configureStore;
